/* Center the container of display message horizontally
Add space to the top of the container */
.displayContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

/* Center the display message horizontally inside the container */
/* Set the size of message to 20px */
/* Set the width of the message list to be 800px for auto overflow */
.messageList{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 20px;
    width: 800px;
}

/* Add space to the left of the display message */
.displayMessage{
    margin-left: 10px;
}