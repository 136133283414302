/* Center the container of display message horizontally
Add space to the top of the container */
.displayContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 1.5rem 0;
}

/* Center the display message horizontally inside the container */
/* Set the size of message to 20px */
/* Set the width of the message list to be 800px for auto overflow */
.messageList {
  display: flex;
  gap: 1.5rem;
  flex-flow: row wrap;
  justify-content: center;
}

.displayMessage {
  text-align: center;
}

.key,
.value {
  margin: 0;
}
