.errorContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.projectIdInput{
    width: 250px;
}

.goButton{
    margin-left: 10px;
}