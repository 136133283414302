/* Set the width of budget bar
Center align the form both horizontally and vertically */
.budgetBar{
    width : 705px;
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    margin: auto;
}   

/* Add space to the top and left of budget count
Make the text of budget count bold */
.budgetCount{
    margin-top:15px;
    margin-left: 10px;
    font-weight: bold;
}