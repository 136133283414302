@import url(http://fonts.cdnfonts.com/css/minecraft-3);
.main {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}
/* Keep the footer stick at the bottom */
.errorPage {
	flex: 1 0 auto;
}

/* Center align the form both horizontally and vertically with extra margin*/
.forumContainer {
	display: flex;
	flex: 1 0 auto;
	justify-content: center;
	align-items: center;
	margin-left: 5%;
	margin-right: 5%;
	min-height: 650px;
}
/* Set the font size of loader to 18px */
.Loader {
	font-size: 18px;
}

/* Add space on the top and side of the header*/
.header {
	margin: 0.5rem 10vw 0 10vw;
}

.irllLogo:hover {
	cursor: url('/ms-icon-144x144.svg') 10 12, pointer;
}

/* Add space to the sides and bottom of the footer */
.pageFooter {
	margin: 0 10vw 2rem 10vw;
}

/* Add space to the left of the content
    Set font size, font and color of content in footer */
.footerContent {
	font-size: 16px;
	color: #777;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
		"Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
		"Noto Color Emoji";
}

/* Set the color of link */
.Link {
	color: #006a30;
}

/* Add space on the left and right of the logo */
.AmiiLogo {
	margin-left: 2rem;
}

.footerImages {
	/* margin: 0; */
	display: flex;
	align-items: flex-end;
}

.UAlogo:hover, .AmiiLogo:hover, .footerImages:hover {
	cursor: url("/ms-icon-144x144.svg") 10 12, pointer;
}

/* Add space to the top and left of the submit button
Set the width of submit button */
.submitButton {
	margin-top: 10px;
	margin-left: 45%;
	width: 150px;
}

.formContainer {
	margin: 1rem 10vw;
}

.copyBox {
	display: block;
	align-items: center;
	text-align: center;
	padding: 1rem;
	margin: 1rem;
	font-size: smaller;
	background-color: rgb(223, 221, 221);
	border: solid 1px rgb(160, 160, 160);
	border-radius: 3px;
}

.copyText {
	text-align: center;
	/* display: inline-block;
	margin-left: auto; */
}

.game {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Set the font size and font for text in model */
.modal {
  font-size: 16px;
  color: #777;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

.debugGrid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
	grid-column-gap: 1rem;
	-webkit-column-gap: 1rem;
	        column-gap: 1rem;
}

.verticalGrid {
  width: min(80vw, 705px);
}

.horizontalGrid {
  display: flex;
  width: min(80vw, 1410px);
  justify-content: space-evenly;
  align-items: center;
}
.verticalOrientationToggle {
  margin-right: auto;
  margin-left: max(10vw, calc((100vw - 705px) / 2));
}

.horizontalOrientationToggle {
  margin-right: auto;
  margin-left: max(10vw, calc((100vw - 1410px) / 2));
}

.custom-cursor {
	cursor: url("/target_cursor.svg") 15 15, auto !important;
}

.scoreModal .ant-skeleton-avatar-circle {
	display: block !important;
	margin: 0.5rem auto;
}

.scoreModal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.scoreModal Button {
	margin-left: auto;
}

.scoreModal h4 {
	text-transform: lowercase;
	font-family: "Roboto", sans-serif;
	-webkit-font-feature-settings: "smcp";
	        font-feature-settings: "smcp";
	font-variant: small-caps;
	font-weight: bold;
	font-size: 1.5em;
	letter-spacing: 0.05em;
	line-height: 0.8;
	color: #1890ff;
}

.scoreModalProgress {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-evenly;
	align-items: center;
}

.scoreModalProgress p {
	/* color: #1890ff; */
	line-height: 1;
	margin: 0;
	padding: 0;
}

.scoreModalProgress div {
	width: 60%;
	height: 2px;
	background-color: black;
	margin: 0.3rem 0;
}

/* Add a solid border to the panel
   Make the corners of panel rounded
   Add space to the top of the panel */
.controlPanel {
  border: solid 1px rgb(237, 237, 237);
  border-radius: 10px;
  margin-top: 20px;
  padding: 1rem 2rem;
  flex: 1 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem auto;
}

/* Remove the default border of the container
 Add space to the top of the container */
.panelContainer {
  border: none;
  margin-top: 10px;
}

.addPadding {
  padding-bottom: 1rem;
}

.direction {
  justify-content: space-evenly;
}

/* Set the height of fps input field
 Make the corners of this field rounded */
.fpsInput {
  width: 7rem;
  height: 2.5rem;
  border-radius: 20px;
}

/* set animation for instructions */
.instructions {
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  transition: 3s;
}

@-webkit-keyframes example {
  0% {
    color: rgb(164, 165, 152);
  }
  20% {
    color: rgb(146, 25, 55);
  }
  40% {
    color: rgb(180, 55, 86);
  }
  60% {
    color: rgb(146, 25, 55);
  }
  80% {
    color: rgb(180, 55, 86);
  }
}

@keyframes example {
  0% {
    color: rgb(164, 165, 152);
  }
  20% {
    color: rgb(146, 25, 55);
  }
  40% {
    color: rgb(180, 55, 86);
  }
  60% {
    color: rgb(146, 25, 55);
  }
  80% {
    color: rgb(180, 55, 86);
  }
}

/* Set the width of the sliders to be more responsive */
.imageControl {
  min-width: 10rem;
  max-width: 30rem;
}

/* Add margin to the bottom of slider text */
.imageControlText {
  margin-bottom: 0.25rem;
}

/* Utility class to add left margin to control
	panel when orientation is horizonal */
.addMargin {
  margin: 0 2rem;
}

/* Add a bottom padding to the image commands */
.imageCommands {
  padding-bottom: 1rem;
}

/* Set the background color for each button
   Set the text color for each button
   Set the border color for each button */
.startButton {
  background-color: #1890ff;
  color: white;
  border-color: #1890ff;
}

.startButton:hover,
.startButton:focus {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

.pauseButton {
  background-color: #fa541c;
  color: white;
  border-color: #fa541c;
}

.pauseButton:hover,
.pauseButton:focus {
  background-color: #ff7a45;
  border-color: #ff7a45;
}

.stopButton {
  background-color: #f5222d;
  color: white;
  border-color: #f5222d;
}

.stopButton:hover,
.stopButton:focus {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}

.resetButton {
  background-color: #52c41a;
  color: white;
  border-color: #52c41a;
}

.resetButton:hover,
.resetButton:focus {
  background-color: #73d13d;
  border-color: #73d13d;
}

.trainOnlineButton {
  background-color: #faad14;
  color: white;
  border-color: #faad14;
}

.trainOnlineButton:hover,
.trainOnlineButton:focus {
  background-color: #ffc53d;
  border-color: #ffc53d;
}

.trainOfflineButton {
  background-color: #13c2c2;
  color: white;
  border-color: #13c2c2;
}

.trainOfflineButton:hover,
.trainOfflineButton:focus {
  background-color: #36cfc9;
  border-color: #36cfc9;
}

.goodButton {
  background-color: #fadb14;
  color: white;
  border-color: #fadb14;
}

.goodButton:hover,
.goodButton:focus {
  background-color: #ffec3d;
  border-color: #ffec3d;
}

.badButton {
  background-color: #a0d911;
  color: white;
  border-color: #a0d911;
}

.badButton:hover,
.badButton:focus {
  background-color: #e8f1cc;
  border-color: #bae637;
}

.undoButton,
.redoButton {
  background-color: white;
  color: black;
  border-color: black;
}

.undoButton:hover,
.undoButton:focus,
.redoButton:hover,
.redoButton:focus {
  background-color: #eeeeee;
  color: black;
  border-color: black;
}

.resetImageButton {
  background-color: #f5222d;
  color: white;
  border-color: #f5222d;
}

.resetImageButton:hover,
.resetImageButton:focus {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}

.submitImageButton {
  background-color: #52c41a;
  color: white;
  border-color: #52c41a;
}

.submitImageButton:hover,
.submitImageButton:focus {
  background-color: #73d13d;
  border-color: #73d13d;
}

.adding,
.adding:hover,
.adding:focus {
  -webkit-animation: blinker 1s ease-in-out infinite alternate;
          animation: blinker 1s ease-in-out infinite alternate;
}

@-webkit-keyframes blinker {
  100% {
    opacity: 60%;
  }
}

@keyframes blinker {
  100% {
    opacity: 60%;
  }
}

.blockButton {
  border: 0.25rem solid #f4aa02;
  border-radius: 8px;
  margin-bottom: 0.5rem;
}

.bottom {
  width: 5rem;
  cursor: pointer;
}

.top {
  width: 7rem;
}

.blockText {
  text-align: center;
  font-size: 2rem;
  font-family: "Minecraft", sans-serif;
  margin: 0;
  text-shadow: 1px 1px 1px black;
  color: rgb(162, 162, 162);
}

/* Set the width of budget bar
Center align the form both horizontally and vertically */
.budgetBar{
    width : 705px;
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    margin: auto;
}   

/* Add space to the top and left of budget count
Make the text of budget count bold */
.budgetCount{
    margin-top:15px;
    margin-left: 10px;
    font-weight: bold;
}
/* Center the container of display message horizontally
Add space to the top of the container */
.displayContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

/* Center the display message horizontally inside the container */
/* Set the size of message to 20px */
/* Set the width of the message list to be 800px for auto overflow */
.messageList{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 20px;
    width: 800px;
}

/* Add space to the left of the display message */
.displayMessage{
    margin-left: 10px;
}
/* Center the container of display message horizontally
Add space to the top of the container */
.displayContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 1.5rem 0;
}

/* Center the display message horizontally inside the container */
/* Set the size of message to 20px */
/* Set the width of the message list to be 800px for auto overflow */
.messageList {
  display: flex;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  flex-flow: row wrap;
  justify-content: center;
}

.displayMessage {
  text-align: center;
}

.key,
.value {
  margin: 0;
}

@media (min-width: 576px) {
    :root {
      --size-divisor: 2;
    }
}
@media (min-width: 768px) {
:root {
    --size-divisor: 1.5;
    }
}
@media (min-width: 992px) {
:root {
    --size-divisor: 1;
    }
}


/* Set the height and width
   Set the style of the border
   Place items in the container vertically*/
.messageContainer{
    height : 605px;
    width: calc(300px / var(--size-divisor));
    overflow : auto;
    border : solid #389e0d;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    margin: auto;
}

/* Center the message title and make it bold */
.messageTitle{
    text-align: center;
    font-weight: bold;
}

/* Set the height and width of the game window
Set the border color and style of the game window
Center align the game window both horizontally and vertically */
.gameWindow {
    height: auto;
    width : auto;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

/* Reference: https://dev.to/leonardoschmittk/how-to-make-a-mouse-ripple-click-effect-with-css-js-and-html-in-2-steps-2fcf */
.ripple {
    width: 3px;
    height: 3px;
    pointer-events: none;
    cursor: pointer;
    background-color: transparent;
    position: fixed;
    border-radius: 50%;
    border: 1px solid #ffffff59;
 }

 @-webkit-keyframes ripple-effect {
    to {
       -webkit-transform:scale(15);
               transform:scale(15);
       opacity: 0.01;
    }
 }

 @keyframes ripple-effect {
    to {
       -webkit-transform:scale(15);
               transform:scale(15);
       opacity: 0.01;
    }
 }

/* Center align the progress bar both horizontally and vertically */
.progressBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

/* Set font size, font and color of text inside the game windows */
.promptText {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    color: #1890ff;
    font-size : 16px;
}

/* Add rounded corners to the border */
.gameContent {
    border-radius: 10px;
}

.gameContent:hover {
    cursor: pointer;
}

/* Add border to each image component */
.imageComponent {
    padding: 5px;
    border : solid #1890ff;
    border-radius: 10px;
}

/* Set the height and width of the container of each image component */
.imageContainer {
    height: 410px;
    width : 410px;
}

/* Set the display style of the main container of the image components */
.container{
    display : flex;
    justify-content: space-evenly;
    align-items: center;
}
/* Set the position style of the fingerprint container */
.fingerprintWindowContainer {
	flex: 1 1;
	height: 605px;
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 10px;
	overflow: hidden;
	background: #0a0a0a;
	border: solid 1px #1890ff;
}

/* Set styling of the fingerprint control buttons */
.fingerprintBtn {
	font-size: 1rem;
	text-align: center;
	min-width: 1.5rem;
	background: #1890ff;
	color: white;
	padding: 0;
	margin: 1px;
	border: none;
	border-radius: 0.25rem;
	cursor: pointer;
}

/* Decrease the font size and add padding to the resize buttons */
.fingerprintResizeBtn {
	font-size: 0.825rem;
	padding: 0.2rem 0.5rem;
}

/* Add a margin between the zoom and resize buttons */
.fingerprintBottomBtn {
	margin-bottom: 1rem;
}

/* Positions the zoom controls to top right of the container and align items to the left */
.fingerprintControls {
	position: absolute;
	top: 1rem;
	right: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

/* Allow user to zoom and pan even if cursor is on minimap */
.fingerprintMinimap {
	pointer-events: none;
}

/* Change button colors for recolor popup menu radiio buttons */
.blueButton {
	color: #1890ff !important;
	background-color: white !important;
	border: solid 1px #1890ff !important;
	outline: white !important;
	margin: 0 1px;
}
.blueButton .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
	background: #1890ff;
}

.greenButton {
	color: #52c41a !important;
	border: solid 1px #52c41a !important;
	background-color: white !important;
	outline: white !important;
	margin: 0 1px;
}
.greenButton > .ant-radio-button,
.greenButton > .ant-radio-button-inner,
.greenButton > .ant-radio-button-wrapper-checked::before,
.greenButton > .ant-radio-button-wrapper-checked,
.greenButton > .ant-radio-button-wrapper {
	background: #52c41a !important;
	color: #52c41a !important;
}

.yellowButton {
	color: #d4b106 !important;
	border: solid 1px #fadb14 !important;
	background-color: white !important;
	outline: white !important;
	margin: 0 1px;
}

.redButton {
	color: #f5222d !important;
	border: solid 1px #f5222d !important;
	background-color: white !important;
	outline: white !important;
	margin: 0 1px;
}

.orangeButton {
	color: #fa8c16 !important;
	border: solid 1px #fa8c16 !important;
	background-color: white !important;
	outline: #fa8c16 !important;
	margin: 0 1px;
}

/* Add padding between all the buttons in the popup menu */
.popupMenu Button:not(:last-of-type) {
	margin-right: 0.5rem;
}

/* Cursor utility classes */
.moveCursor {
	cursor: move;
}

.pointerCursor {
	cursor: pointer;
}

/* Set the height and width of the game window
Set the border color and style of the game window
Center align the game window both horizontally and vertically */
/* .gameWindow {
	height: auto;
	width: auto;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
} */

.grid {
  position: relative;
}

/* Add a blue border along outside of grid box */
.grid:after {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
  border-radius: 3px;
  border: solid 3px #1890ff;
}

/* Add a black border along inside of grid box */
.grid:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: solid 1px black;
  z-index: 2;
  pointer-events: none;
}

/* Style tiles */
.tile {
  position: absolute;
  border-left: solid 1px black;
  border-bottom: solid 1px black;
  display: grid;
  place-items: center;
  font-size: 1.5rem;
  pointer-events: all;
  cursor: pointer;
  z-index: 1;
}

.selectedTile::before {
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: #1890ff;
  opacity: 0.5;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.errorContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.projectIdInput{
    width: 250px;
}

.goButton{
    margin-left: 10px;
}
