/* Set the height and width of the game window
Set the border color and style of the game window
Center align the game window both horizontally and vertically */
.gameWindow {
    height: auto;
    width : auto;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

/* Reference: https://dev.to/leonardoschmittk/how-to-make-a-mouse-ripple-click-effect-with-css-js-and-html-in-2-steps-2fcf */
.ripple {
    width: 3px;
    height: 3px;
    pointer-events: none;
    cursor: pointer;
    background-color: transparent;
    position: fixed;
    border-radius: 50%;
    border: 1px solid #ffffff59;
 }

 @keyframes ripple-effect {
    to {
       transform:scale(15);
       opacity: 0.01;
    }
 }

/* Center align the progress bar both horizontally and vertically */
.progressBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

/* Set font size, font and color of text inside the game windows */
.promptText {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    color: #1890ff;
    font-size : 16px;
}

/* Add rounded corners to the border */
.gameContent {
    border-radius: 10px;
}

.gameContent:hover {
    cursor: pointer;
}

/* Add border to each image component */
.imageComponent {
    padding: 5px;
    border : solid #1890ff;
    border-radius: 10px;
}

/* Set the height and width of the container of each image component */
.imageContainer {
    height: 410px;
    width : 410px;
}

/* Set the display style of the main container of the image components */
.container{
    display : flex;
    justify-content: space-evenly;
    align-items: center;
}