/* Add space to the sides and bottom of the footer */
.pageFooter {
	margin: 0 10vw 2rem 10vw;
}

/* Add space to the left of the content
    Set font size, font and color of content in footer */
.footerContent {
	font-size: 16px;
	color: #777;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
		"Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
		"Noto Color Emoji";
}

/* Set the color of link */
.Link {
	color: #006a30;
}

/* Add space on the left and right of the logo */
.AmiiLogo {
	margin-left: 2rem;
}

.footerImages {
	/* margin: 0; */
	display: flex;
	align-items: flex-end;
}

.UAlogo:hover, .AmiiLogo:hover, .footerImages:hover {
	cursor: url("/ms-icon-144x144.svg") 10 12, pointer;
}
