@media (min-width: 576px) {
    :root {
      --size-divisor: 2;
    }
}
@media (min-width: 768px) {
:root {
    --size-divisor: 1.5;
    }
}
@media (min-width: 992px) {
:root {
    --size-divisor: 1;
    }
}


/* Set the height and width
   Set the style of the border
   Place items in the container vertically*/
.messageContainer{
    height : 605px;
    width: calc(300px / var(--size-divisor));
    overflow : auto;
    border : solid #389e0d;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    margin: auto;
}

/* Center the message title and make it bold */
.messageTitle{
    text-align: center;
    font-weight: bold;
}
